import React from "react";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import Layout from "../components/layout";
import SEO from "../components/seo";
import CoffeeImage from "../components/coffeeimage";


const IndexPage = () => (
  <Layout>
    <SEO title="Home" description="Diversity and inclusion is the best policy." keywords="Diversity,Inclusion,DEI,Inclusive Interview,Fairness" />
    <h1>Includere</h1>
    <h2>verb [transitive] /in’kludere/ to include</h2>
    <p>
      The project is brewing. <OutboundLink href="https://s05op3bn9kd.typeform.com/to/WDq1RzMn">Sign up to be one of the first to use Includere</OutboundLink>.
    </p>
    <div style={{ maxWidth: `1080px`, marginBottom: `1.45rem` }}>
      <CoffeeImage />
      <p class="source">
        <Link to="https://www.behance.net/gallery/97480561/Instant-Guide-to-Making-Coffee">
          Source
        </Link>
      </p>
    </div>
  </Layout>
);

export default IndexPage;
